<template>
	<div class="council">
		<SubHeader title="Player Ranking" subtitle="プレイヤーランキング" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<ul class="nav nav-pills" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								data-toggle="tab"
								href="#a"
								@click="
									group_id = null;
									getPlayerRanking();
								"
								>すべて</a
							>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#a"
								@click="
									group_id = 1;
									getPlayerRanking();
								"
							>
								<span class="group">A</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#b"
								@click="
									group_id = 2;
									getPlayerRanking();
								"
							>
								<span class="group">B</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#c"
								@click="
									group_id = 3;
									getPlayerRanking();
								"
							>
								<span class="group">C</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#d"
								@click="
									group_id = 4;
									getPlayerRanking();
								"
							>
								<span class="group">D</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#e"
								@click="
									group_id = 5;
									getPlayerRanking();
								"
							>
								<span class="group">E</span>ディビジョン
							</a>
						</li>
						<!--<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#f"
								@click="
									group_id = 6;
									getPlayerRanking();
								"
							>
								<span class="group">F</span>ディビジョン
							</a>
						</li>-->
					</ul>

					<div class="table-responsive my-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">Team</th>
									<th scope="col" class="text-nowrap">Player</th>
									<th scope="col" class="text-nowrap">Win</th>
									<th scope="col" class="text-nowrap">Leg</th>
									<th scope="col" class="text-nowrap">Avg</th>
									<th scope="col" class="text-nowrap">Ton</th>
									<th scope="col" class="text-nowrap">Join Pts</th>
									<th scope="col" class="text-nowrap">Avg Pts</th>
									<th scope="col" class="text-nowrap">Total Pts</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(player, index) in list">
									<tr :key="'rank' + index">
										<td class="text-nowrap">{{ player.rank }}</td>
										<td class="text-nowrap">{{ player.team_name }}</td>
										<td class="text-nowrap">{{ player.player_name }}</td>
										<td class="text-nowrap">{{ player.win_match }}</td>
										<td class="text-nowrap">{{ player.legs }}</td>
										<td class="text-nowrap">
											<span v-if="Math.floor(player.avg_darts) != 99">
												{{ player.avg_darts }}
											</span>
											<span v-else>--</span>
										</td>
										<td class="text-nowrap">{{ player.ton }}</td>
										<td class="text-nowrap">{{ player.join_point }}</td>
										<td class="text-nowrap">{{ player.avg_point }}</td>
										<td class="text-nowrap">{{ player.total_point }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "schedule",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			list: [],
			group_id: null,
		};
	},
	mounted: function () {
		this.getPlayerRanking();
	},
	methods: {
		getPlayerRanking: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {
				group_id: this.group_id,
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/player_ranking", {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
	padding: 4rem 1rem 0 1rem;
	text-align: left;
}
.table-bordered {
	border: 1px solid #999;
	td,
	th {
		border: 1px solid #999;
		vertical-align: middle;
	}
}
.list-group-item {
	border: none;
	background-color: transparent;
	padding: 0 1.25rem;
	.badge {
		font-size: 1.2rem;
	}
}
</style>
